.accordion-wrap-faq.lecture-wrap {
  .accordion__list2 {
    margin-bottom: 0;
    &:last-child .accordion-body-text {
      border-bottom: 1px solid #e9eaf0;
    }
    &:last-child .link {
      border-bottom: 1px solid #e9eaf0;
    }
  }
  .accordion-header-content {
    font-size: 16px;
    color: #1d2026;
    font-weight: 500;
    @include mq('phone-wide', max) {
      font-size: 15px;
    }
    svg {
      margin-right: 7px;
      transition: all 0.3s;
    }
  }
  .link {
    padding: 20px;
    border: 1px solid #e9eaf0;
    border-bottom: 1px solid transparent;
    &:hover {
      border: 1px solid #e9eaf0;
      box-shadow: none;
    }
  }
  .accordion__list2.open {
    .link {
      background-color: #f5f7fa;
      border: 1px solid transparent;
    }
  }
  .accordion__list2.open {
    .accordion-header-content {
      color: #ff6636;
      svg {
        transform: rotate(180deg);
        path {
          stroke: #ff6636;
        }
      }
    }
  }
  .accordion-body-text {
    box-shadow: none;
    border: 1px solid #e9eaf0;
    border-top: none;
    border-bottom: none;
    padding: 0;
  }
}

.lectue-body-item {
  display: flex;
  padding: 14px 20px;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
  justify-content: space-between;
  &.active {
    background: #ffeee8;
    label.form-check-label {
      color: #1d2026;
      font-weight: 500;
    }
    .form-check-input {
      border: 1px solid #ff6636 !important;
    }

    .lecture-play {
      path {
        fill: #1d2026;
      }
    }
  }
  &:hover {
    background-color: #f5f7fa;
  }
  .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    cursor: pointer;
    border: 1px solid #ced1d9 !important;
    input[type='checkbox']:checked {
      border-color: #ff6636 !important;
    }
  }
  label.form-check-label {
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #4e5566;
    cursor: pointer;
  }
  input[type='checkbox']:checked + .form-check-label {
    color: #4e5566;
  }
  .lecture-play {
    path {
      fill: #a1a5b3;
    }
  }
}

.sub-heading-meta {
  @include mq('desktop-wide', max) {
    margin-top: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0 8px;
      display: inline-block;

      font-size: 14px;
      letter-spacing: -0.01em;
      color: #4e5566;
      span {
        color: #8c94a3;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
