.company-content {
  margin-right: 10px;
  @include mq('tablet', max) {
    text-align: center;
    margin-bottom: 30px;
  }
  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #1d2026;
    margin-bottom: 20px;
    @include mq('desktop-wide', max) {
      font-size: 30px;
    }
    @include mq('desktop', max) {
      font-size: 26px;
    }
    @include mq('tablet', max) {
      margin-bottom: 10px;
    }
  }
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #6e7485;
    margin-right: 110px;
    @include mq('desktop', max) {
      margin-right: 0;
    }
  }
}

.company-logo-item {
  a {
    width: 200px;
    height: 100px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: 0px 0px 32px rgb(9 26 68 / 7%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    @include mq('desktop-wide', max) {
      width: 100%;
      height: 85px;
    }
  }
}

.company-logo-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
