.student-breadcrumb {
  height: 280px;
  width: 100%;
  background: #ffeee8;
}

.student-title {
  margin-bottom: 24px;
  h2 {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.01em;
    color: #1d2026;
    margin: 0;
  }
}

.lecture-slider .owl-nav {
  position: absolute;
  top: -64px;
  right: -3px;
}

.lecture-slider .owl-next,
.lecture-slider .owl-prev {
  width: 40px;
  height: 40px;
  left: 48px;
  line-height: 40px;
  margin: 0 4px;
  background: #ffeee8 !important;
}

label {
  float: none;
}

label.student-lebel {
  float: none;

  font-size: 12px;
  line-height: 16px;
  color: #6e7485;
  display: block;
  margin-bottom: 5px;
}

.student-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
  @include mq('tablet', max) {
    display: block;
  }
}
.student-search {
  flex: 0 0 40%;
  @include mq('tablet', max) {
    margin-bottom: 20px;
  }
  input {
    background-color: transparent;
    border: 1px solid #e9eaf0;
  }
  .dashboard-search-icon {
    top: 32px;
  }
}
.student-header-dropdwon-wrap {
  flex: 0 0 58%;
  display: flex;
  justify-content: space-between;
  @include mq('tablet-small', max) {
    display: block;
  }
  .student-header-dropdwon-item {
    flex: 0 0 31%;
    @include mq('tablet-small', max) {
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
    }
    .custom-select-trigger {
      margin: 0;
      border: 1px solid #e9eaf0;
      line-height: 48px;
      padding: 0 18px;
      color: #4e5566;
      font-size: 16px;
      &::after {
        border-bottom: 2px solid #1d2026;
        border-right: 2px solid #1d2026;
      }
    }
    .custom-select {
      display: block;
    }
    .custom-select-wrapper {
      display: block;
    }
  }
}

.student-header.my-course {
  .form-searchbox {
    input {
      background-color: #fff;
      border: 1px solid #fff;
    }
  }
  span.custom-select-trigger {
    background-color: #fff;
    border: 1px solid #fff;
  }
}

.course-item-4-card-info-price {
  del {
    font-size: 14px;
    color: #a1a5b3;
  }
}

// student course page
.course-student-column {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mq('tablet-small', max) {
    display: block;
  }
  .course-column4 {
    flex: 0 0 23.7%;
    margin-bottom: 24px;
    @include mq('desktop-wide', max) {
      flex: 0 0 32%;
    }
    @include mq('desktop', max) {
      flex: 0 0 48%;
    }
  }
  @include mq('desktop', max) {
    flex: 0 0 48%;
  }
}

.course-lecture.course-column4.tablet {
  display: none;
  @include mq('desktop-wide', max) {
    display: block;
  }
}

// student instrocutor
.student-instructor-section {
  .course-item-5 {
    margin-bottom: 24px;
  }
}
.course-item-5-card-img.team-thumb-instructor {
  height: 312px;
}
.course-lecture-footer.student-instructor {
  padding: 0 15px 14px;
}

// student wishost

.wishlist-body-text,
.shopping-body-text {
  .wishlist-body-bottom,
  .shopping-body-bottom {
    border: 1px solid #e9eaf0;

    .faq-review-wrap {
      padding: 24px;
      .faq-review-item {
        border-bottom: 1px solid #e9eaf0 !important;
        &:last-child {
          border-bottom: 0 !important;
          padding-bottom: 0;
        }
      }
    }
  }
}

.student-wishist-header {
  border-bottom: 1px solid #e9eaf0;
  padding: 20px 24px;
  display: flex;
  @include mq('tablet', max) {
    justify-content: space-between;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #4e5566;
  }
  p.one {
    flex: 0 0 46.6%;
    @include mq('tablet', max) {
      flex: 0 0 auto;
    }
  }
  p.two {
    flex: 0 0 19.5%;
    @include mq('tablet', max) {
      flex: 0 0 auto;
    }
  }
}

.student-wishist-header.student-shopping-header {
  p.one {
    flex: 0 0 63.6%;
    @include mq('tablet', max) {
      flex: 0 0 auto;
    }
  }
}

//student setting

.wrapper {
  position: relative;
  border: 1px solid #e9eaf0;
  padding: 44px;
  margin-right: 44px;
  @include mq('desktop', max) {
    padding: 25px;
    margin-right: 25px;
  }
  @include mq('tablet', max) {
    margin: 0 0 20px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #6e7485;
    padding: 10px 15px 0;
  }
  #img-result {
    cursor: pointer;
    margin: 0;
    position: relative;
    display: block;
    width: 100%;
    height: 280px;
    color: rgba(0, 0, 0, 0);
    border: none;
    overflow: hidden;
    z-index: 0;
    transition: all 0.3s;
    background-size: cover;
    background-position: center center;
    background-image: url(./src/images/team9.jpg);
    @include mq('desktop-wide', max) {
      height: 260px;
    }
    @include mq('tablet', max) {
      height: 350px;
    }
    &.no-image {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0;
        width: 100%;
        height: 48px;
        transition: all 0.3s;
        background: rgba(0, 0, 0, 0.5);
      }
    }
    &.no-image {
      &::after {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        transition: all 0.3s;
        left: 50%;
        bottom: 10px;
        opacity: 0;
        background-size: 24px;
        transition: all 0.3s;
        background-position: center bottom;
        transform: translateX(-50%);
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.0625 7.68647L12 3.75L15.9375 7.68647' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 14.2492V3.75195' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.25 14.25V19.5C20.25 19.6989 20.171 19.8897 20.0303 20.0303C19.8897 20.171 19.6989 20.25 19.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V14.25' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
.no-image:hover::before,
.no-image:hover::after {
  opacity: 1 !important;
}

.student-account-setting {
  border-bottom: 1px solid #e9eaf0;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.change-password-wrap {
  padding-right: 70px;
  @include mq('desktop', max) {
    padding-right: 40px;
  }
  @include mq('tablet', max) {
    padding: 0;
  }
}

.delete-account-wrap {
  padding-left: 70px;
  margin-top: 40px;
  position: relative;
  @include mq('desktop', max) {
    padding-left: 40px;
  }
  @include mq('tablet', max) {
    padding: 0;
  }
  &:before {
    content: '';
    left: -5px;
    top: 40px;
    height: 222px;
    width: 1px;
    position: absolute;
    background-color: #e9eaf0;
    @include mq('tablet', max) {
      content: none;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #6e7485;
    margin-bottom: 15px;
  }
  ul {
    margin: 0 0 24px;
    padding: 0;
    list-style: none;
    li {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #6e7485;
      padding-left: 15px;
      position: relative;
      &:before {
        content: '';
        left: 0;
        top: 10px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        background-color: #6e7485;
      }
    }
  }
}
