// Button component
.button {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  padding: 0px 16px;
  text-align: center;
  white-space: nowrap;
  display: inline-block;
  letter-spacing: -0.04em;
  text-transform: capitalize;
  background-color: #ff6636;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #cc522b;
    box-shadow: 0px 6px 20px 0px #cc522b80;
  }

  // buttons size
  &--md {
    font-size: 16px;
    line-height: 48px;
    padding: 0px 24px;
    letter-spacing: -0.008em;
  }

  &--lg {
    font-size: 18px;
    line-height: 56px;
    padding: 0px 32px;
    letter-spacing: -0.01em;
  }

  // icons
  &-i {
    span {
      margin-right: 15px;
      display: inline-block;
    }

    &--r {
      span {
        margin-left: 15px;
      }
    }
  }

  // Disable buttons
  &-d {
    user-select: none;
    pointer-events: none;
    background: #ffddd1;
    &:hover {
      box-shadow: none;
    }

    // remove box shaddow

    &--p-s,
    &--p-t,
    &--s,
    &--s-s,
    &--s-t,
    &--g,
    &--g-s,
    &--g-t {
      &:hover {
        box-shadow: none;
      }
    }

    // button primary
    &--p-s {
      color: #ffa386;
      background-color: #ffeee8;
    }

    &--p-t {
      color: #ffa386;
      background-color: transparent;
    }

    // buttons secondary
    &--s {
      background-color: #cdcbfe;
      &:hover {
        background-color: #cdcbfe;
      }
    }

    &--s-s {
      color: #9a95fe;
      background-color: #ebebff;
      &:hover {
        background-color: #ebebff;
      }
    }

    &--s-t {
      color: #9a95fe;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }

    // buttons gray
    &--g {
      background-color: #ced1d9;
      &:hover {
        background-color: #ced1d9;
      }
    }

    &--g-s {
      color: #b7bac7;
      background-color: #f5f7fa;
      &:hover {
        background-color: #f5f7fa;
      }
    }

    &--g-t {
      color: #b7bac7;
      background-color: transparent;
      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  // button primary secondary
  &-p-s {
    color: #ff6636;
    background-color: #ffeee8;
    &:hover {
      color: #cc522b;
      background-color: #ffddd1;
    }
  }

  // button primary transparent
  &-p-t {
    color: #ff6636;
    background-color: transparent;
    &:hover {
      color: #cc522b;
      background-color: #ffeee8;
    }
  }

  // button secondary
  &-s {
    background: #564ffd;

    &:hover {
      background-color: #453fca;
      box-shadow: 0px 6px 20px 0px #453fca80;
    }
  }

  // button secondary - secondary
  &-s-s {
    color: #564ffd;
    background-color: #ebebff;
    &:hover {
      color: #453fca;
      box-shadow: none;
      background-color: #cdcbfe;
    }
  }

  // button secondar transparent
  &-s-t {
    color: #564ffd;
    background-color: transparent;
    &:hover {
      color: #453fca;
      box-shadow: none;
      background-color: #ebebff;
    }
  }

  // button gray
  &-g {
    background-color: #1d2026;
    &:hover {
      background-color: #363b47;
      box-shadow: none;
      box-shadow: 0px 6px 20px 0px #363b4780;
    }
  }

  // button gray secondary
  &-g-s {
    color: #1d2026;
    background-color: #f5f7fa;
    &:hover {
      color: #363b47;
      box-shadow: none;
      background-color: #e9eaf0;
    }
  }

  // button gray transparent
  &-g-t {
    color: #1d2026;
    background-color: transparent;
    &:hover {
      color: #1d2026;
      box-shadow: none;
      background-color: #f5f7fa;
    }
  }
}

.edit-biography-content .edit-biography-right .edit-btn,
.edit-biography-content .edit-biography-right .notification {
  transition: 0.3s;
}

.edit-biography-content .edit-biography-right .notification:hover {
  background-color: $primary-600;

  svg {
    path {
      stroke: #fff;
    }
  }
}

.edit-biography-content .edit-biography-right .edit-btn:hover {
  background-color: $primary-600;
}

.dashboard-content .dashboard-right .notification {
  transition: 0.3s;
}

.dashboard-content .dashboard-right .notification:hover {
  background-color: $primary-600;

  svg {
    path {
      stroke: #fff;
    }
  }
}

label {
  width: 100%;
  float: left;
  user-select: none;
}

.center {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

select {
  z-index: 3;
  float: left;
}

/** Custom Select **/
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
  cursor: pointer;
}

.custom-select-wrapper select {
  display: none;
}

.custom-select {
  position: relative;
  display: inline-block;
  font: 13px/1.5 'Roboto', sans-serif;
}

.custom-select-trigger {
  position: relative;
  display: block;
  min-width: 115px;
  padding: 0;
  font-weight: 400;
  color: #6e7485;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;

  font-size: 14px;
  margin-left: 20px;
  transition: all 0.3s ease-in-out;
}

.custom-select-trigger:after {
  position: absolute;
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  top: 50%;
  right: 20px;
  margin-top: -3px;
  border-bottom: 2px solid #c7d1d6;
  border-right: 2px solid #c7d1d6;
  transform: rotate(45deg) translateY(-50%);
  transition: all 0.35s ease-out;
  transform-origin: 50% 0;
}

.custom-select.opened .custom-select-trigger:after {
  margin-top: 3px;
  transform: rotate(-135deg) translateY(-50%);
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0%;
  right: 0;
  width: 100%;
  margin: 15px 0;
  border-radius: 0;
  padding: 10px 0;
  box-sizing: border-box;
  background: #fff;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  border: 0;
  z-index: 9;
  border: 1px solid #e9eaf0;
  border-top: none;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
}

.custom-select.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0x);
}

.option-hover:before {
  background: #f9f9f9;
}

.custom-option {
  position: relative;
  display: block;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
  transition: all 0.05s ease-in-out;

  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: #4e5566;
  transition: all 0.3s;
  padding: 5px 18px;
}
.custom-option:hover {
  color: #1d2026 !important;
  background: #ffeee8;
}

.custom-option:first-of-type {
  border-radius: 4px 4px 0 0;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.custom-option:last-of-type {
  border-bottom: 0;
  border-radius: 0 0 4px 4px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.custom-option:hover,
.custom-option.selection {
  color: #b5b5b5;
}
.btn-parimary {
  background-color: $primary-500;
  border: 0;
  color: #fff;
}
.input-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: -0.008em;
  height: 48px;
  width: 143px;
  text-align: center;
}
@media (max-width: 575px) {
  .input-btn {
    font-size: 14px;
  }
}

.card-btn {
  background-color: transparent;
  border: 0;
}
.lecture-card-btn {
  background-color: $primary-100;
  color: $primary-500;
  border: 0;
  line-height: 40px;
  padding: 0 16px;
  font-weight: 600;
  transition: 0.3s;
  text-align: center;
}
.lecture-card-btn:hover {
  background-color: $primary-500;
  color: #fff;
}
.lecture-card-btn-watched {
  width: 100%;
}
.visa-btn {
  width: 100%;
  display: flex;
  font-size: 16px;
  padding: 36px 0;
  color: #1d2026;
  transition: 0.3s;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border: 1px dashed #e9eaf0;
  svg {
    margin-right: 8px;
  }
}
.visa-btn:hover {
  background-color: $primary-100;
  color: $primary-500;
  border: 1px dashed transparent;
}

.rotate {
  -moz-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  path {
    stroke: $primary-500;
  }
}
.rotate.rotate-down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
// All Default btn

.btn-wrap-column {
  display: flex;
  justify-content: center;
}
.btn-wrap {
  text-align: center;
  margin: 0 7px;
}
a.default-btn,
button.default-btn {
  display: inline-block;
  font-weight: 600;
  letter-spacing: -0.004em;
  text-transform: capitalize;
  color: #fff;
  transition: all 0.3s;
  text-align: center;
  border: none;
  &.icon-btn {
    svg {
      margin-left: 10px;
    }
  }
  &.icon-btn-left {
    svg {
      margin-right: 10px;
    }
  }
}
a.default-btn.bg-gray {
  color: #1d2026;
  background: #f5f7fa;
}
a.default-btn.bg-white {
  color: #ff6636;
  background-color: #fff;
}
a.default-btn.bg-orange,
button.default-btn.bg-orange {
  background: #ff6636;
  &:hover {
    background-color: #cc522b;
  }
}
a.default-btn.btn-small,
button.default-btn.btn-small {
  padding: 0 16px;
  font-size: 14px;
  line-height: 40px;
}
a.default-btn.btn-medium,
button.default-btn.btn-medium {
  padding: 0 24px;
  font-size: 16px;
  line-height: 48px;
}
a.default-btn.btn-large,
button.default-btn.btn-large {
  padding: 0 32px;
  font-size: 18px;
  line-height: 56px;
  @include mq('phone-wide', max) {
    padding: 0 25px;
    font-size: 16px;
    line-height: 50px;
  }
}

a.default-btn.bg-tuscany {
  background: #cc522b;
  box-shadow: 0px 6px 20px rgba(204, 82, 43, 0.5);
}
a.default-btn.bg-watusi {
  background: #ffddd1;
}
a.default-btn.bg-pink {
  color: #ff6636;
  background: #ffeee8;
}
a.default-btn.bg-watusi2 {
  color: #cc522b;
  background: #ffddd1;
}
a.default-btn.bg-pink2 {
  color: #ffa386;
  background: #ffeee8;
}

a.default-btn.color-orange {
  color: #ff6636;
}

a.default-btn.bg-pink3 {
  color: #cc522b;
  background: #ffeee8;
}
a.default-btn.color-pink {
  color: #ffa386;
}
