.contact-us-section {
  padding-top: 16px;
  border-bottom: 1px solid #ffddd1;
  @include mq('tablet', max) {
    padding: 60px 0;
  }
}
.about-content.contact-content {
  p {
    font-size: 18px;
    line-height: 24px;
  }
}

.section-title {
  h2{
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #4e5566;
  }
}

.branches-wrap {
  @include mq('desktop', max) {
    margin-bottom: 24px;
  }
}
.branches-thumb {
  height: 364px;
  position: relative;
  @include mq('desktop', max) {
    height: 450px;
  }
  @include mq('tablet-small', max) {
    height: auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.branches-data {
  text-align: center;
  background-color: #fff;
  padding: 0px 31px 16px 31px;
  position: absolute;
  bottom: 24px;
  width: calc(100% - 48px);
  margin-left: 24px;
  transition: all 0.3s;
  &:hover {
    padding: 16px 31px 16px 31px;
  }
  h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #ff6636;
    transition: all 0.3s;
    opacity: 0;
    margin: 0 0 3px;
  }
  &:hover h5 {
    opacity: 1;
  }
  h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1d2026;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #6e7485;
  }
}

.contact-section {
  background-color: #f5f7fa;
}
.contact-wrap {
  background: #ffffff;
  padding: 40px;
  @include mq('phone-wide', max) {
    padding: 30px;
  }
}
.contact-title {
  margin-bottom: 30px;
  h5 {
    font-size: 24px;
    line-height: 32px;
    color: #1d2026;
    @include mq('tablet-small', max) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #8c94a3;
  }
}
.contact-info-wrap {
  margin-right: 100px;
  @include mq('desktop-wide', max) {
    margin-right: 15px;
  }
  @include mq('desktop', max) {
    margin-right: 0;
  }
}
.contact-info-item {
  border-bottom: 1px solid #e9eaf0;
  display: flex;
  padding: 30px 0;
  @include mq('phone-wide', max) {
    padding: 20px 0;
  }
  @include mq('phone-wide', max) {
    display: block;
  }
  &:last-child {
    border-bottom: 0;
  }
  h5 {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    color: #ff6636;
    margin: 0;
    flex: 0 0 40%;
    @include mq('phone-wide', max) {
      margin-bottom: 15px;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #1d2026;
    a {
      color: #1d2026;
    }
  }
}
#map {
  height: 424px;
  width: 100%;
}
