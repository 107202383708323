/* ------------------------------------------------------------------------------

  Template Name: E-Tutor
  Description: Education & Online Learning Management System Figma Template 
  Author: templatecookie
  Author URI: http://themeforest.net/user/templatecookie
  Version: 1.0
  
    1.  Vendor

        1.1 Bootstrap

        
    2.  Base  

        2.1 typography
        2.2 reset
 

    3.  Abstracts

        3.1 Variables
        3.2 Functions
        3.3 Placeholder

    4.  Layout

        4.1 footer
        4.2 message
        4.3 header
        4.4 register
        4.5 instructor
        4.6 breadcrumb

    5.  Components

        5.1 hero
        5.2 form
        5.3 card
        5.4 faq
        5.5 cta
        5.6 badge
        5.7 alert
        5.8 modal
        5.9 footer
        5.10 range
        5.11 review
        5.12 header
        5.13 filter
        5.14 profile
        5.15 lecture
        5.16 company
        5.17 buttons
        5.18 trailer
        5.19 progress
        5.20 ckeditor
        5.21 checkbox
        5.22 calendar
        5.23 fielpond
        5.24 activity
        5.25 category
        5.26 curriculum
        5.27 pagination
        5.28 breadcrumb
        5.29 dashboard
        5.30 social-icon
        5.31 notification
        5.32 payment-card
        5.33 feature-card
        5.34 all-dropdown
        5.35 profileview
        5.36 download-file
        5.37 popular tools
        5.38 left sidebar
        5.39 dashboard menu
        5.40 edit-biography
        5.41 payment-method
        5.42 rating-progress
        5.43 target-audience
        5.44 student-profile
        5.45 purchase history
        5.46 social media box

    6. Pages

        6.1 404
        6.2 about
        6.3 career
        6.4 contact
        6.5 course
        6.6 student
        6.7 contact
        6.8 instructor
        6.9 gift course 
        6.10 coming soon
        6.11 become instructor


/*-------------------------------------------------------------------------------
  1. Vendor
-------------------------------------------------------------------------------*/


/*-------------------------------------------------------------------------------
  2. Base
-------------------------------------------------------------------------------*/

// 3. abstracts
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/placeholder';

@import 'base/typography';
@import 'base/reset';



// 4. layout
@import 'layout/footer';
@import 'layout/message';
@import 'layout/header';
@import 'layout/register';
@import 'layout/instructor';
@import 'layout/breadcrumb';

// components
@import 'components/hero';
@import 'components/form';
@import 'components/card';
@import 'components/faq';
@import 'components/cta';
@import 'components/badge';
@import 'components/alert';
@import 'components/modal';
@import 'components/footer';
@import 'components/range';
@import 'components/review';
@import 'components/header';
@import 'components/filter';
// @import 'components/select';
@import 'components/profile';
@import 'components/lecture';
@import 'components/company';
@import 'components/buttons';
@import 'components/trailer';
@import 'components/progress';
@import 'components/ckeditor';
@import 'components/checkbox';
@import 'components/calendar';
@import 'components/fielpond';
@import 'components/activity';
@import 'components/category';
@import 'components/curriculum';
@import 'components/pagination';
@import 'components/breadcrumb';
@import 'components/dashedboard';
@import 'components/social-icon';
@import 'components/notification';
@import 'components/payment-card';
@import 'components/feature-card';
@import 'components/all-dropdown';
@import 'components/profileview';
@import 'components/download-file';
@import 'components/popular-tools';
@import 'components/left-slidebar';
@import 'components/dashboard-menu';
@import 'components/edit-biography';
@import 'components/payment-method';
@import 'components/rating-progress';
// @import 'components/target-audience';
@import 'components/student-profile';
@import 'components/purchase-history';
@import 'components/social-media-box';

// pages
@import 'pages/404';
@import 'pages/about';
@import 'pages/career';
@import 'pages/contact';
@import 'pages/course';
@import 'pages/student';
@import 'pages/contact';
@import 'pages/instructor';
@import 'pages/gift-course';
@import 'pages/coming-soon';
@import 'pages/become-instructor';
@import 'pages/custom';
