.faq-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
  @include mq('tablet-small', max) {
    display: block;
    margin-bottom: 30px;
  }
  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 1;
    letter-spacing: -0.01em;
    color: #1d2026;
    @include mq('tablet', max) {
      font-size: 30px;
    }
    @include mq('tablet-small', max) {
      font-size: 26px;
      line-height: 34px;
    }
  }
}
.faq-left-sidebar {
  margin-right: 14px;
  border: 1px solid #e9eaf0;
  padding: 8px 0;
  @include mq('desktop', max) {
    margin: 0;
  }
  .nav{
    ul{
      margin: 0;
      padding: 0;
      list-style: none;
    }
    display: block;
    .nav-link {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #1d2026;
      display: block;
      padding: 12px 16px;
      width: 100%;
      border-radius: 0;
      text-align: left;
      transition: all 0.3s;
      border-bottom: 1px solid #e9eaf0;
      &.active{
        color: #fff;
        background-color: #FF6636;
      }
    }
    
  }
  @include mq('tablet', max) {
    margin: 0 0 20px;
  }

  
}

.faq-right-sidebar {
  background: #f5f7fa;
  padding: 20px;
  margin-left: 14px;
  @include mq('desktop', max) {
    margin: 0;
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1d2026;
    margin-bottom: 8px;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    color: #6e7485;
    margin-bottom: 24px;
  }
}
.sidebar-field {
  margin-bottom: 12px;
  input,
  textarea {
    width: 100%;
    height: 42px;
    background: #ffffff;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #8c94a3;
    border: none;
    padding: 10px 16px;
    &::-webkit-input-placeholder {
      color: #8c94a3;
    }
    &:-moz-placeholder {
      color: #8c94a3;
    }
    &::-moz-placeholder {
      color: #8c94a3;
    }
    &:-ms-input-placeholder {
      color: #8c94a3;
    }
    &::-ms-input-placeholder {
      color: #8c94a3;
    }
    &::placeholder {
      color: #8c94a3;
    }
  }
  textarea {
    height: 120px;
    resize: none;
  }
}

.accordion__list {
  margin-bottom: 20px;
  .link {
    background: #ffffff;
    border: 1px solid #e9eaf0;
    padding: 18px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 500;
    font-size: 16px;
    transition: all 0.3s;
    @include mq('tablet-small', max) {
      font-size: 15px;
      padding: 10px 15px;
    }
    &:hover {
      border: 1px solid #e9eaf0;
      box-shadow: 0px 12px 32px rgba(29, 32, 38, 0.1);
    }
    svg {
      transition: all 0.3s;
      path {
        transition: all 0.3s;
      }
    }
    &:hover svg {
      path {
        stroke: #ff6636;
      }
    }
    span.link__title {
      @include mq('phone-wide', max) {
        flex: 0 0 85%;
      }
    }
  }
}
.accordion__list.open {
  .link {
    color: #fff;
    background-color: #1d2026;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.accordion-wrap {
  .open {
    svg {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

.accordion-body-text {
  display: none;
  padding: 24px;
  box-shadow: 0px 12px 32px rgba(29, 32, 38, 0.1);
  @include mq('tablet-small', max) {
    padding: 20px;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4e5566;
  }
  .faq-description1,
  .faq-description2 {
    ul {
      margin: 0;
      padding: 20px 0;
      list-style: none;
      li {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #4e5566;
      }
    }
  }
  .faq-description2 {
    ul {
      li {
        padding-left: 12px;
        position: relative;
        &:before {
          content: '';
          left: 0;
          top: 8px;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          background-color: #4e5566;
        }
      }
    }
  }
}
