.range-middle {
    position: relative;
    width: 100%;
    max-width: 100%;

    .slider {
        position: relative;
        z-index: 1;
        height: 6px;
    }

    .slider>.track {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: #FFDDD1;
    }

    .slider>.range {
        position: absolute;
        z-index: 2;
        left: 26%;
        right: 26%;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        background-color: #FF6636;
    }

    .slider>.thumb {
        position: absolute;
        z-index: 3;
        width: 12px;
        height: 12px;
        background-color: #FF6636;
        border: 2px solid #fff;
        border-radius: 50%;
        box-shadow: 0px 2px 6px rgba(29, 32, 38, 0.1);
        transition: box-shadow .3s ease-in-out;

    }

    .slider>.thumb.left {
        left: 25%;
        margin-left: 10px;
        top: 7px;
        transform: translate(-15px, -10px);
    }

    .slider>.thumb.right {
        right: 25%;
        margin-right: 10px;
        top: 7px;
        transform: translate(15px, -10px);
    }

    .slider>.thumb.hover {
        box-shadow: 0 0 0 5px rgba(255, 102, 54, .5);
    }

    .slider>.thumb.active {
        box-shadow: 0 0 0 10px rgba(255, 102, 54, .7);
    }

    input[type=range] {
        position: absolute;
        pointer-events: none;
        -webkit-appearance: none;
        z-index: 2;
        height: 10px;
        width: 100%;
        opacity: 0;
    }

    input[type=range]::-webkit-slider-thumb {
        pointer-events: all;
        width: 30px;
        height: 30px;
        border-radius: 0;
        border: 0 none;
        background-color: red;
        -webkit-appearance: none;
    }


    .youtube-link {
        position: fixed;
        left: 20px;
        bottom: 20px;
        color: #000;
        text-decoration: none;
        font-size: 12px;
    }
}

.price-field-wrap {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    .default-input-field {
        flex: 0 0 48%;
        position: relative;
        input{
            padding: 10px 20px 10px 35px;
        }
        .dollar {
            color: #1D2026;
            position: absolute;
            top: 13px;
            left: 20px;
        }
    }
}