.main-logo-wrap {
  display: flex;
  align-items: center;
}
.logo-content{
  @include mq('phone-wide', max) {
    display: none;
  }
  h2{
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -0.03em;
    color: #1D2026;
    line-height: 1;
    margin: 0;
    margin-left: 9px;
  }
}

.header-bottom.header-four.coming-soon{
  background-color: transparent;
  display: flex;
  .social-icon{
    margin: 0;
  }
}
.logo-cap {
  flex: 0 0 auto;
}

.coming-soon-section {
  padding: 150px 0 100px;
  @include mq('desktop', max) {
    padding: 100px 0 60px;
  }
}

.coming-soon-content{
  h4{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FF6636;
    margin-bottom: 15px;
  }
  h2{
    font-weight: 600;
    font-size: 64px;
    line-height: 68px;
    letter-spacing: -0.02em;
    color: #1D2026;
    @include mq('desktop-wide', max) {
      font-size: 56px;
      line-height: 60px;
    }
    @include mq('desktop', max) {
      font-size: 48px;
      line-height: 54px;
    }
    @include mq('tablet-small', max) {
      font-size: 36px;
      line-height: 46px;
    }
    @include mq('phone-wide', max) {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

.countdown-wrap {
  max-width: 550px;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  @include mq('tablet', max) {
    max-width: 480px;
  }
  .countdown-item {
    width: 116px;
    height: 108px;
    left: 0px;
    top: 0px;
    background: #F5F7FA;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq('desktop', max) {
      width: 100px;
      height: 100px;
    }
    @include mq('phone-wide', max) {
      width: 80px;
      height: 90px;
    }
    .number{
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #1D2026;
      margin-bottom: 6px;
      @include mq('phone-wide', max) {
       font-size: 30px;
       margin-bottom: 0;
      }
    }
      span.time_content {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #1D2026;
    }
  }
}

.coming-soon-thumb {
  text-align: center;
}

.notify-wrap {
  background: #FFFFFF;
  box-shadow: 0px 16px 80px rgb(0 0 0 / 12%);
  padding: 32px;
  margin-top: -110px;
  z-index: 1;
  position: relative;
  @include mq('phone-wide', max) {
    padding: 20px;
   }
   button.button.button--lg {
    width: 100%;
    margin-top: 15px;
}
  .course-searchbox-input{
    margin-bottom: 24px;
    @include mq('phone-wide', max) {
      margin-bottom: 15px;
      display: block;
     }
    input{
      height: 56px;
    }
    .course-search-icon-left{
      top: 50%;
      transform: translateY(-50%);
    }
  }
 
   @include mq('phone-wide', max) {
    svg.course-search-icon-left{
      top: 22%!important;
    }
   }
   
  h4{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #1D2026;
    margin-bottom: 24px;
    @include mq('phone-wide', max) {
      font-size: 20px;
      margin-bottom: 15px;
     }
  }
  p{
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4E5566;
  }
}

.copywright-text {
  width: 100%;
}
.footer-bottom-menu {
  width: 100%;
  text-align: right;
  @include mq('tablet-small', max) {
    text-align: center;
  }
}

.course-searchbox-input{
  @include mq('phone-wide', max) {
    margin-bottom: 15px;
    display: block;
   }
  }
   @include mq('phone-wide', max) {
    button.button.button--md {
        width: 100%;
        margin-top: 15px;
    }
   }