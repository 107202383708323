.social-icon {
  margin-top: 16px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0 2px;
      display: inline-block;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        width: 48px;
        height: 48px;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        color: #4e5566;
        line-height: 20px;
        align-items: center;
        transition: all 0.3s;
        background: #f5f7fa;
        justify-content: center;
        letter-spacing: -0.01em;
        @include mq('desktop', max) {
          width: 44px;
          height: 44px;
        }
        &:hover {
          color: #fff;
          background-color: #ff6636;
          box-shadow: 0px 6px 20px 0px #cc522b80;
          path {
            fill: #fff;
          }
        }

        svg {
          transition: all 0.3s;
        }
        path {
          transition: all 0.3s;
        }
      }
    }
  }
}

// footer social
.footer-social {
  margin-top: 24px;
  ul {
    display: flex;
    li {
      margin: 0 6px;
      a {
        width: 46px;
        height: 46px;
        background-color: #272b33;
      }
    }
  }
}

.app-btn-column {
  a.app-btn {
    margin-bottom: 12px;
    @include max(767px) {
      margin: 0px;
      margin-right: 12px;
    }
    @include max(480px) {
      width: 100%;
      margin-bottom: 12px;
      &:nth-last-child(1) {
        margin: 0;
      }
    }
    .app-btn-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 163px;
      height: 58px;
      background: #272b33;
      @include max(480px) {
        width: 100%;
      }
      .app-icon {
        svg {
          margin-right: 15px;
        }
        img {
          margin-right: 15px;
        }
      }
      .app-data {
        p {
          font-size: 10px;
          color: #b7bac7;
          line-height: 1;
          margin-bottom: 4px;
        }
        h5 {
          font-weight: 500;
          font-size: 16px;
          color: #fff;
          margin: 0;
        }
      }
    }
  }
}
