.instructor-profile-section {
  padding: 40px 0 80px;
}

.instructor-about-content {
  border: 1px solid #e9eaf0;
  padding: 24px;
  h4 {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    text-transform: uppercase;
    color: #1d2026;
    margin-bottom: 15px;
  }
  p {
    font-family: 'Inter';
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #6e7485;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.student-tabs-wrap.instructor-tabs-wrap {
  .nav {
    justify-content: flex-start;
    border: none;
    border-bottom: 1px solid #e9eaf0;
  }
  .nav-pills .nav-link {
    width: 200px;
  }
}

.course-item-4.instructor-profile {
  .course-item-5-card-img {
    height: 312px;
  }
  .course-item-5-card-info-tag {
    font-size: 12px;
  }
  .course-item-5-card-info-price {
    font-size: 24px;
    font-weight: 600;
  }

  .course-item-5-card-head {
    font-size: 18px;
    line-height: 24px;
    transition: all 0.3s;
    color: #1d2026 !important;
  }
  .course-item-5-card-info {
    padding: 20px 20px 0 20px;
  }
  .course-item-5-card-head {
    padding: 12px 20px 16px;
  }
  .course-item-5-card-footer-review {
    font-size: 16px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .feature-course-footer {
    border: none;
    padding: 0;
  }
  .feature-course-footer {
    p.p-color {
      font-size: 16px;
    }
    span {
      font-size: 16px;
    }
  }
  .course-item-5-card-footer {
    padding: 20px;
  }
}

// become instructor
.join-team-content.become-instructor {
  @include mq('tablet', max) {
    margin: 0;
  }
}
.about-content.become-instructor {
  margin-right: 0;
  h2{
    font-size: 64px;
    margin-bottom: 32px;
    line-height: 68px;
    @include mq('desktop-wide', max) {
      font-size: 55px;
      margin-bottom: 25px;
    }
    
    @include mq('desktop-wide', max) {
      font-size: 46px;
      margin-bottom: 15px;
    }
    @include mq('tablet', max) {
      font-size: 40px;
      margin-bottom: 5px;
    }
    @include mq('tablet-small', max) {
      font-size: 35px;
    }
  }
  p {
    font-size: 24px;
    margin-bottom: 40px;
    @include mq('desktop-wide', max) {
      font-size: 20px;
      line-height: 28px;
    }
    @include mq('tablet-small', max) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

// counter section
.counter-section.become-instructor {
  padding: 45px 0;
  background: #ffeee8;
}

//teaching Etutor

.join-team-content.become-instructor {
  .checkbox-field {
    ul {
      margin-top: 26px;
      li {
        padding: 0;
        padding-left: 45px;
        margin-bottom: 24px;
        @include max(991px) {
          margin-bottom: 12px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          left: 0;
        }
        span {
          @extend %text-two;
          white-space: normal;
        }
      }
    }
  }
}

.become-successful-instructor {
  background: #f5f7fa;
}

// instrucetor rule
.about-section.instructor-rules {
  border: none;
}
.about-content.instructor-rules {
  margin-right: 80px;
  @include mq('tablet', max) {
    margin: 0;
  }
  h2 {
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 48px;
    margin-right: 20px;
    @include mq('tablet', max) {
      font-size: 36px;
      line-height: 40px;
    }
    @include mq('tablet-small', max) {
      font-size: 30px;
      line-height: 36px;
    }
   
  }
  p {
    font-size: 18px;
    line-height: 24px;
  }
  ul {
    margin: 24px 0 0;
    padding: 0;
    list-style: none;
    li {
      line-height: 24px;
      color: #1d2026;
      position: relative;
      padding-left: 15px;
      margin-bottom: 12px;
      &::before {
        content: '';
        left: 0;
        top: 10px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        background-color: #1d2026;
      }
    }
  }
}

// help center sections

.help-center-section {
  background: #ffeee8;
}

.join-team-content.help-center-content {
  p {
    color: #6e7485;
  }
  .course-list-section {
    margin: 33px 0;
    ul {
      li {
        color: #1d2026;
      }
    }
  }
}

.help-center-wrap {
  display: flex;
  align-items: center;
  a.help-center-icon {
    width: 56px;
    height: 56px;
    background: #ffffff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    margin-right: 16px;
  }

  .help-center-data {
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: #8c94a3;
      margin-bottom: 8px;
    }
    a {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #1d2026;
    }
  }
}

// gallery section

.our-mission-content.become-instructor {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-size: 20px;
    margin-bottom: 32px;
    line-height: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.testimonial2-slider-item {
  background: #ffeee8;
  padding: 32px;
  @include mq('tablet-small', max) {
    padding: 25px;
  }
  svg {
    margin-bottom: 20px;
  }
  p {
    font-size: 18px!important;
    line-height: 26px!important;
    letter-spacing: -0.015em;
    color: #1d2026;
    @include max(575px) {
      font-size: 16px !important;
      line-height: normal;
    }
  }
}
.my-owl-nav {
  display: flex;
  margin-top: 32px;
}
.my-next-button,
.my-prev-button {
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin-right: 12px;
  transition: all 0.3s;
  background: #f5f7fa !important;
  &:hover {
    background: #ff6636 !important;
    box-shadow: 0px 6px 20px 0px #cc522b80;
  }
  svg {
    path {
      stroke: #1d2026;
      transition: all 0.3s;
    }
  }
  &:hover path {
    stroke: #fff;
  }
}
