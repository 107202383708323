.contact-us-section.career-page {
  border: none;
}

.join-team-section {
  background: #f5f7fa;
}
.join-team-thumb-wrap {
  display: flex;
  img {
    width: 100%;
  }
  @include mq('tablet', max) {
    justify-content: center;
    margin-bottom: 30px;
  }
  @include mq('phone-wide', max) {
    display: block;
  }
  .join-team-thumb {
    margin-right: 24px;
    @include mq('phone-wide', max) {
      margin: 0 0 20px;
    }
    &:last-child {
      margin: 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:nth-child(1) {
      width: 200px;
      height: 425px;
      @include mq('phone-wide', max) {
        width: 100%;
        height: auto;
      }
    }
    &:nth-child(2) {
      width: 200px;
      height: 521.83px;
      @include mq('phone-wide', max) {
        width: 100%;
        height: auto;
      }
    }
    &:nth-child(3) {
      width: 200px;
      height: 448px;
      margin-top: 74px;
      @include mq('phone-wide', max) {
        width: 100%;
        height: auto;
        margin: 0;
      }
    }
  }
}

.join-team-content {
  h2 {
    font-size: 40px;
    font-weight: 600;
    color: #1d2026;
    line-height: 48px;
    margin-bottom: 18px;
    letter-spacing: -0.01em;

    @include max(767px) {
      font-size: 32px;
      line-height: 42px;
    }
  }
  p {
    @extend %text-two;
    font-weight: 400;
    color: #4e5566;
    @include max(767px) {
      font-size: 16px;
      line-height: normal;
    }
  }
  .checkbox-field {
    ul {
      margin-top: 40px;
      @include mq('desktop-wide', max) {
        margin-top: 30px;
      }
      li {
        padding: 30px;
        margin-bottom: 32px;
        padding-left: 75px;
        background-color: #fff;
        &:last-child {
          margin-bottom: 0;
        }
        @include mq('phone-wide', max) {
          padding: 20px;
          padding-left: 60px;
        }
        svg {
          left: 30px;
          width: 30px;
          height: 30px;
          @include mq('phone-wide', max) {
            left: 20px;
          }
        }
        span {
          @extend %text-two;
          width: 100%;
          margin-bottom: 4px;
          display: inline-block;
        }
      }
    }
  }

  &.become-instructor {
    margin-left: 45px;
  }
}

.benefit-package {
  border-bottom: 1px solid #e9eaf0;
}
.gray-bg {
  position: relative;
  z-index: 0;
  &::before {
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 83%;
    z-index: -1;
    position: absolute;
    background-color: #f5f7fa;
  }
}

.company-main-wrap {
  background-color: #fff;
  padding: 80px 80px 60px;
  border: 1px solid #e9eaf0;
  width: calc(100% + 160px);
  margin-left: -80px;
  @include mq('desktop-wide', max) {
    width: 100%;
    padding: 30px;
    margin: 0;
  }
  .company-content {
    margin-right: 7px;
  }
}

.open-position-wrap {
  background: #ffffff;
  margin-bottom: 24px;
  .open-position-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    @include mq('desktop-wide', max) {
      padding: 20px;
    }
    a.arrow-icon {
      display: flex;
      width: 48px;
      height: 48px;
      background: #ffeee8;
      align-items: center;
      justify-content: center;
    }
    p {
      font-family: 'Inter';
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #4e5566;
      span {
        color: #e34444;
      }
    }
  }
}

.open-position-header {
  padding: 24px;
  border-bottom: 1px solid #e9eaf0;
  @include mq('desktop-wide', max) {
    padding: 20px;
  }
  h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #1d2026;
    margin-bottom: 20px;
  }
  .sub-heading-meta {
    ul {
      display: flex;
      li {
        display: flex;
        align-items: center;
        @include mq('desktop-wide', max) {
          margin: 0 4px;
        }
        @include mq('phone-wide', max) {
          font-size: 13px;
        }
        svg {
          margin-right: 6px;
          @include mq('desktop-wide', max) {
            margin-right: 4px;
          }
        }
      }
    }
  }
}

// single career
.single-career-section {
  background: #f5f7fa;
}
.single-career-herader-right {
  width: 100%;
  text-align: right;
  @include mq('tablet-small', max) {
    text-align: left;
    margin-top: 30px;
  }
}

.single-career-herader {
  .open-position-header {
    padding: 0;
    border: 0;
    margin-bottom: 40px;
    @include mq('tablet-small', max) {
      margin-bottom: 24px;
    }
    .sub-heading-meta {
      margin-bottom: 12px;
      ul {
        li {
          @include mq('phone-wide', max) {
            font-size: 12px;
          }
        }
      }
    }
    h2 {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.01em;
      color: #1d2026;
      @include mq('tablet', max) {
        font-size: 36px;
        line-height: 40px;
      }
      @include mq('tablet-small', max) {
        font-size: 30px;
        line-height: 36px;
      }
      @include mq('phone-wide', max) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}

.header-info-wrap {
  display: flex;
  align-items: center;
  @include mq('phone-wide', max) {
    display: block;
  }
  .header-info-item {
    margin-right: 150px;
    @include mq('tablet', max) {
      margin-right: 40px;
    }
    @include mq('phone-wide', max) {
      margin: 0 0 20px;
    }
    &:last-child {
      margin-right: 0;
    }
    h4 {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #ff6636;
      margin-bottom: 8px;
    }
    p,
    a {
      font-family: 'Inter';
      font-size: 16px;
      line-height: 24px;
      color: #1d2026;
    }
  }
}

.single-career-body-section {
  padding: 40px 0 80px;
}

.single-career-body-wrap {
  max-width: 650px;
  .course-single-title {
    margin-bottom: 15px;
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #1d2026;
      margin: 0;
    }
  }
  .overview-description {
    @include mq('tablet-small', max) {
      margin-bottom: 25px;
    }
    p {
      color: #6e7485;
    }
  }
  .course-list-section {
    @include mq('tablet-small', max) {
      margin-bottom: 25px;
    }
    ul {
      li {
        color: #6e7485;
        margin-bottom: 10px;
        padding-left: 25px;
        svg {
          top: 6px;
          width: 15px;
        }
      }
    }
  }
  .single-career-herader-right {
    text-align: center;
  }
}
