.activity {
  margin: 0;
  padding: 0px;
  //   height: 480px;
  height: 385px;
  overflow: hidden;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffeee8;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #ff6636;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  &-item {
    display: flex;
    padding: 12px 20px;
    transition: all 0.3s linear;
    &:hover {
      background-color: #ffeee8;
    }
  }
  &-icon {
    width: 32px;
    height: 32px;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
    background-color: #ff6636;
    img {
      color: #fff;
      height: 16px;
    }
  }

  &-info {
    p {
      @extend %text-one;
      color: #4e5566;
      margin-bottom: 6px;
      .username {
        color: #1d2026;
        font-weight: 600;
      }
    }
    .time {
      font-size: 12px;
      color: #8c94a3;
      line-height: 16px;
      text-transform: capitalize;
    }
  }
}
