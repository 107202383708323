.mola{
    font-size: 12px;
    line-height: 16px;
    color: #8C94A3;
}
.pointer{
    cursor: pointer;
}
.up-title{
    font-size: 12px;
    line-height: 16px;
    color: #6E7485 ;
}
.h-320{
    height: 320px;
}
.img-show-box{
    height: 200px;
    width: 100%;
    position: relative;
    img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    span{
        display:inline-block ;
        margin-right: 10px;
    }
    label{
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        background:rgba(0, 0, 0, 0.5);
        color: #FFFFFF;
        font-size: 14px;
        line-height: 20px;
        padding: 12px; text-align: center;
        z-index: 4;
    }
}