.bredadcrumb-section {
  background-color: #f5f7fa;
  padding: 40px 0;
}

.breadcrumb-menu {
  text-align: center;
  &.text-left {
    text-align: left;
    margin-bottom: 16px;
  }
  h3 {
    @extend %text-three;
    margin: 6px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0 2px;
      display: inline-block;
      &:nth-last-child(1) {
        a {
          color: #1d2026;
        }
      }
      a {
        @extend %text-one;
      }
    }
  }
}
