.header-top {
  padding: 0 34px 0 24px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #1d2026;
  @include mq('desktop', max) {
    padding: 20px 34px 20px 24px;
    background-color: #fff;
  }
  .header-right {
    flex: 0 0 30%;
    display: flex;
    justify-content: flex-end;
  }
  .main-menu {
    flex: 0 0 70%;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        a {
          font-weight: 500;
          font-size: 14px;
          letter-spacing: -0.01em;
          color: #8c94a3;
          padding: 16px 20px;
          position: relative;
          transition: all 0.3s;
          &:hover {
            color: white;
          }
          &:before {
            content: '';
            left: 50%;
            width: 0;
            top: 0;
            position: absolute;
            height: 2px;
            background-color: #ff6636;
            transition: 0.3s;
            transform: translateX(-50%);
          }
          &:hover::before {
            width: 100%;
          }
        }
        a.active {
          color: white;
        }
        a.active::before {
          width: 100%;
        }
      }
    }
    @include mq('desktop', max) {
      display: none;
    }
  }
}

.header-right {
  @include mq('desktop', max) {
    display: none !important;
  }
  .select-item {
    .custom-select-trigger {
      min-width: inherit;
      padding-right: 15px;
      margin-left: 25px;
      color: #a1a5b3;
      &:after {
        width: 7px;
        height: 7px;
        right: 0;
        margin-top: -1px;
        border-bottom: 1px solid #a1a5b3;
        border-right: 1px solid #a1a5b3;
      }
    }
    .custom-options {
      margin: 18px 0;
      padding: 3px 0;
      border: none;
      border-radius: 4px;
    }
    .custom-option {
      padding: 2px 10px;
    }
  }
}

.header-bottom {
  padding: 24px 32px;
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  @include mq('desktop', max) {
    display: none;
  }
  .header-bottom-left {
    flex: 0 0 70%;
    @include mq('desktop-wide', max) {
      flex: 0 0 60%;
    }
    @include mq('tablet', max) {
      flex: 0 0 40%;
    }
    .header-bottom-left-wrap {
      display: flex;
      align-items: center;
      .main-logo {
        margin-right: 50px;

        @include mq('desktop-wide', max) {
          margin-right: 20px;
        }
      }
      span.custom-select-trigger {
        border: 1px solid #e9eaf0;
        min-width: 200px;
        height: 48px;
        padding: 0 18px;
        font-size: 16px;
        line-height: 24px;
        color: #4e5566;
        display: flex;
        align-items: center;
        margin: 0;
        @include mq('desktop-wide', max) {
          min-width: 150px;
        }
      }
    }
  }
}

.search-field-wrap {
  .form-searchbox {
    width: 424px;
    margin-left: 16px;
    @include max(1380px) {
      width: 100%;
    }

    @include max(1200px) {
      padding: 12px;
      margin-left: 0px;
    }

    .icon {
      @include max(1200px) {
        left: 25px;
      }
    }
  }
  button#search-btn {
    border: none;
    background-color: transparent;
    position: absolute;
    left: 20px;
    top: 10px;
  }
}

.search-field input[type='search']::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #8c94a3;
  opacity: 1;
}
.search-field input[type='search']:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #8c94a3;
  opacity: 1;
}
.search-field input[type='search']::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #8c94a3;
  opacity: 1;
}
.search-field input[type='search']:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8c94a3;
  opacity: 1;
}
.search-field input[type='search']::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8c94a3;
  opacity: 1;
}

.header-bottom-right {
  flex: 0 0 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include mq('desktop-wide', max) {
    flex: 0 0 40%;
  }
  @include mq('tablet', max) {
    flex: 0 0 50%;
  }
  .header-btn-wrap {
    p {
      font-size: 14px;
      color: #4e5566;
      line-height: 22px;
      margin-right: 16px;
      letter-spacing: -0.01em;
      text-transform: capitalize;
      @include max(575px) {
        display: none;
      }
    }

    &.modal-btn {
      .button {
        &:nth-child(1) {
          color: #ff6636;
          background-color: #fff;
          margin-right: 12px;
        }
      }
    }

    a.header-btn {
      display: inline-block;
      padding: 0 24px;
      font-family: Inter;
      font-weight: 600;
      font-size: 16px;
      line-height: 48px;
      letter-spacing: -0.008em;
      text-transform: capitalize;
      color: #ff6636;
      margin-right: 8px;
      transition: all 0.3s;
      &:last-child {
        margin-right: 0;
      }
    }

    .header-btn.orange-btn {
      background: #ffeee8;
      &:hover {
        color: white;
        background-color: $primary-600;
      }
    }

    .header-btn.div-orange-btn {
      color: #fff;
      background-color: #ff6636;
      &:hover {
        background-color: $primary-600;
      }
    }
  }
}

.header-cart-wrap {
  margin-right: 12px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0 10px;
      display: inline-block;
      a {
        position: relative;
        span.count {
          font-family: Inter;
          font-weight: 500;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          color: #fff;
          width: 16px;
          height: 16px;
          background: #ff6636;
          display: block;
          padding: 3px;
          border-radius: 100%;
          position: absolute;
          top: -2px;
          right: -2px;
        }
      }
      a.bell-icon {
        &:before {
          content: '';
          right: 3px;
          top: 3px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: absolute;
          background-color: #ff6636;
        }
      }
    }
  }
}

.mobile-user-wrap {
  margin-top: 60px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e9eaf0;
  @include max(420px) {
    display: block;
    text-align: center;
  }
  .mobile-use-left {
    display: flex;
    align-items: center;
    flex: 0 0 55%;
    @include max(420px) {
      display: block;
      margin-bottom: 15px;
    }
    .mobile-user-thumb {
      width: 48px;
      height: 48px;
      overflow: hidden;
      border-radius: 100%;
      flex: 0 0 auto;
      margin-right: 12px;
      @include max(420px) {
        margin: 0 auto 10px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .mobile-user-data {
      a {
        display: block;
      }
      h5 {
        font-weight: 500;
        font-size: 16px;
        color: #1d2026;
        margin-bottom: 4px;
      }
      p {
        font-family: Inter;
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #8c94a3;
      }
    }
  }
  .mobile-cart {
    .header-cart-wrap {
      margin: 0;
      ul {
        margin: 0;
        li {
          border: none;
          @include mq('desktop', max) {
            margin: 0 5px;
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          a {
            padding: 0;
          }
        }
      }
    }
  }
}

/* offcanvas menu */
.main-menu {
  display: block;
}
.offcanves-menu {
  display: none;
  @include mq('desktop', max) {
    display: block;
  }
}
.offcanves-menu {
  width: 300px;
  background: #ffffff;
  height: 100%;
  position: fixed;
  z-index: 111;
  margin-left: -300px;
  transition: 0.3s;
  padding-right: 0px;
  height: 100vh;
  overflow-y: scroll;
  ul {
    margin: 0 0 26px;
    padding: 0;
    list-style: none;
    li {
      position: relative;
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: 0px;
      }
      a {
        font-family: Inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #6e7485;
        padding: 14px 20px;
        transition: all 0.3s;
        display: block;
        &:hover {
          color: #ff6636;
        }
        span {
          width: 20px;
          height: 20px;
          top: 0px;
          background: #ff6636;
          border-radius: 20px;
          font-family: Inter;
          font-weight: 500;
          font-size: 12px;
          color: #fff;
          float: right;
          text-align: center;
        }
      }
    }
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    color: #1d2026;
    margin-left: 20px;
  }
}
.offcanves-menu.active {
  margin-left: 0px;
  z-index: 1111;
  width: 340px;
  @include max(340px) {
    width: 290px;
  }
}
.off-menu span.menubar {
  font-size: 28px;
  cursor: pointer;
  transition: all linear 0.3s;
  color: #fff;
}
.off-menu {
  float: right;
}
.close {
  cursor: pointer;
}
.close:hover {
  color: #242d48;
  border-color: #242d48;
}

.offcanvas-overlay {
  width: 100%;
  height: 100%;
  background: #000;
  position: absolute;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.offcanvas-overlay.active {
  opacity: 0.3;
  z-index: 111;
  visibility: visible;
}
.menu-bar {
  display: none;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
  @include mq('desktop', max) {
    display: block;
  }
}

.responsive-logo {
  display: none;
  svg {
    width: 148px;
  }
  @include mq('desktop', max) {
    display: block;
  }
}

.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  .mobile-logo {
    svg {
      width: 148px;
    }
  }
}

a.header-user {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.header-bottom.header-three {
  background-color: #f5f7fa;
  @include mq('desktop', max) {
    display: flex;
  }
  @include mq('tablet', max) {
    display: block;
    text-align: center;
  }
  .header-bottom-right {
    @include mq('tablet', max) {
      margin-top: 20px;
      flex: 0 0 100%;
      display: block;
    }
  }
  .header-bottom-left-wrap {
    @include mq('tablet', max) {
      display: block;
    }
  }
}
a.header-arrow {
  width: 58px;
  height: 58px;
  background-color: #fff;
  text-align: center;
  line-height: 58px;
  border-radius: 100%;
  flex: 0 0 auto;
  margin-right: 16px;
  @include mq('tablet', max) {
    margin: 0 0 20px;
  }
}

.header-bottom-meta {
  h4 {
    font-weight: 500;
    font-size: 18px;
    color: #1d2026;
    margin-bottom: 12px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      a {
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #4e5566;
        display: flex;
        svg {
          margin-right: 8px;
        }
      }
    }
  }
}

header.header-section.header-four {
  background-color: #fff;
  .main-logo {
    a {
      display: flex;
      align-items: center;
      font-size: 32px;
      font-weight: 600;
      color: #1d2026;
      line-height: 38px;
      letter-spacing: -0.03em;
      text-transform: capitalize;
      @include max(480px) {
        font-size: 0px;
        line-height: 0px;
      }
      img {
        margin-right: 8px;
      }
    }

    &--white {
      a {
        color: #fff;
      }
    }
  }
}

.header-four {
  box-shadow: inset 0px -1px 0px #e9eaf0;
}

.header-bottom.header-four {
  display: flex;
  padding: 24px 0;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  @include mq('desktop', max) {
    display: flex;
  }

  .header-bottom-right {
    flex: 0 0 auto;
    display: block;
  }
}

// notification

.card.card-activity.notification-box {
  position: absolute;
  width: 430px;
  right: 0;
  top: 30px;
  z-index: 1;
  opacity: 0;
  z-index: 99;
  visibility: hidden;
  border: 1px solid #e9eaf0;
  background-color: #fff;
  transition: all 0.2s;
  box-shadow: 0px 4px 20px rgba(29, 32, 38, 0.12);
  .activity-item {
    margin: 0 !important;
    display: flex !important;
  }
  p.mark-read {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4e5566;
  }
}

.notification-visiable .card.card-activity.notification-box {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s;
}

.card.card-activity.notification-box.instructor-page {
  top: 58px;
}

.header-btn-wrap {
  button.button.button--md {
    @include max(390px) {
      margin: 0 0 15px;
    }
  }
}
