.footer-header {
  box-shadow: inset 0px -1px 0px rgb(54 59 71 / 50%);
  padding: 100px 0;

  @include max(991px) {
    padding: 60px 0px;
  }

  @include mq('tablet-small', max) {
    padding: 70px 0;
  }
  @include mq('phone-wide', max) {
    padding: 70px 0 55px;
  }
  .footer-header-content {
    margin-right: 30px;
    @include mq('tablet', max) {
      margin: 0;
      margin-bottom: 30px;
    }
    @include mq('phone-wide', max) {
      margin-bottom: 15px;
    }

    h3 {
      @extend %text-four;
      color: #fff;
      margin-bottom: 18px !important;
      @include max(991px) {
        font-size: 36px;
      }
      @include max(575px) {
        font-size: 32px;
        line-height: 42px;
      }
    }
    a.footer-btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 48px;
      letter-spacing: -0.008em;
      text-transform: capitalize;
      color: #fff;
      padding: 0 24px;
      margin-right: 12px;
      transition: all 0.3s;
      @include mq('phone-wide', max) {
        padding: 0 20px;
        line-height: 45px;
        margin-bottom: 15px;
      }
    }
    a.footer-btn.orange-btn {
      background-color: #ff6636;
      &:hover {
        color: white;
        background-color: $primary-600;
      }
    }
    a.footer-btn.gray-btn {
      background-color: #282b31;
      &:hover {
        color: white;
        background-color: $primary-600;
      }
    }
  }
  .footer-counter-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include mq('phone-wide', max) {
      display: block;
    }
    @include max(991px) {
      margin-top: 20px;
    }
    .footer-counter-item {
      @include mq('phone-wide', max) {
        margin-bottom: 20px;
      }
      @include max(991px) {
        text-align: center;
      }
      h3 {
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.01em;
        color: #fff;
        margin-bottom: 12px;
        @include max(991px) {
          font-size: 28px;
          line-height: 38px;
        }
        @include max(767px) {
          margin-bottom: 6px;
        }
      }
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #b7bac7;
      }
    }
  }
}

.footer-header.footer-header2 {
  .footer-header-content {
    margin-right: 202px;
    @include mq('desktop-wide', max) {
      margin-right: 115px;
    }
    @include mq('desktop', max) {
      margin-right: 30px;
    }
    @include mq('tablet', max) {
      margin: 0;
    }
    h3 {
      margin-bottom: 24px;
    }
    p {
      @extend %text-one;
      color: #b7bac7;
      font-size: 16px;
      margin-bottom: 40px;
      letter-spacing: normal;
    }
    a.footer-btn.orange-btn {
      line-height: 64px;
      font-size: 20px;
      padding: 0 40px;
      @include mq('phone-wide', max) {
        line-height: 60px;
        font-size: 18px;
        padding: 0 35px;
        margin: 0;
      }
    }
  }
}

.footer-section {
  background-color: #1d2026;
}
.footer-top {
  padding: 80px 0;
  @include mq('desktop', max) {
    padding: 70px 0 40px;
  }
  @include max(767px) {
    padding-bottom: 10px;
  }
  .footer-textarea {
    margin-right: 90px;
    @include mq('desktop', max) {
      margin: 0;
      margin-bottom: 30px;
    }
    p {
      @extend %text-one;
      color: #8c94a3;
    }
    .footer-logo {
      margin-bottom: 20px;
    }
  }
  .footer-widget {
    @include max(767px) {
      margin-bottom: 30px;
    }
    .footer-menu-title {
      margin-bottom: 22px;
      @include mq('desktop', max) {
        margin-bottom: 20px;
      }
      h4 {
        font-family: Inter;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: #fff;
        margin: 0;
      }
    }
    .footer-menu-title.dowmload-app {
      margin-bottom: 20px;
    }
    .footer-menu {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          margin-bottom: 6px;
          &:nth-last-child(1) {
            margin-bottom: 0px;
          }
          a {
            @extend %text-one;
            position: relative;
            color: #8c94a3;
            &:hover {
              color: #fff;
            }
            &:before {
              content: '';
              left: 0;
              bottom: -5px;
              width: 0;
              height: 2px;
              transition: all 0.3s;
              position: absolute;
              background-color: #ff6636;
            }
            &:hover::before {
              width: 100%;
            }
            svg {
              transition: all 0.3s;
              opacity: 0;
              position: relative;
              top: -2px;
            }
            &:hover {
              svg {
                margin-left: 11px;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.footer-bottom {
  box-shadow: inset 0px 1px 0px rgb(54 59 71 / 50%);
  padding: 24px 0;
  @include mq('tablet-small', max) {
    text-align: center;
  }
  .copywright-text {
    @include mq('tablet-small', max) {
      width: 100%;
    }
    p {
      font-size: 14px;
      letter-spacing: -0.01em;
      color: #8c94a3;
      a {
        color: #fff;
      }
    }
  }
  .select-box {
    @include mq('tablet-small', max) {
      width: 100%;
    }
  }
  .custom-select-trigger {
    min-width: 208px;
    padding: 12px 18px;
    color: #8c94a3;
    border-radius: 0;
    font-size: 16px;
    margin-left: 0;
    border: 1px solid #363b47;
    @include max(575px) {
      margin-bottom: 16px;
    }
  }
  .custom-options {
    top: -230%;
  }
}

.footer-bottom.footer-three {
  box-shadow: none;
  padding: 24px 0;
  .copywright-text {
    p {
      color: #6e7485;
      a {
        color: #1d2026;
      }
    }
  }
  .footer-bottom-menu {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        margin: 0 10px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 14px;
          letter-spacing: -0.01em;
          color: #1d2026;
          transition: all 0.3s;
          &:hover {
            color: #ff6636;
          }
        }
      }
    }
  }
}
