.section-title.text-left {
  text-align: left;
  margin-bottom: 0;
  h3 {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.01em;
    color: #1d2026;
    margin: 0;
  }
}

.popular-tools-items {
  border: 1px solid #e9eaf0;
  text-align: center;
  height: 94px;
  line-height: 94px;
  vertical-align: middle;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s;
  background-color: #fff;
  margin: 24px 1px 40px;
  &:hover {
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 12px 32px rgba(29, 32, 38, 0.1);
  }
  &:hover h5 {
    color: #ff6636;
  }
  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1d2026;
    transition: all 0.3s;
  }
  p {
    font-size: 12px;
    line-height: 16px;
    color: #8c94a3;
  }
}

.tools-slider .owl-nav {
  position: absolute;
  top: -25px;
  right: -3px;
}
.tools-slider .owl-next,
.tools-slider .owl-prev {
  margin: 0 4px;
}

.popular-keyword {
  display: flex;
  align-items: center;
  @include mq('desktop-wide', max) {
    display: block;
  }
  p {
    margin-right: 24px;

    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #1d2026;
    @include mq('desktop-wide', max) {
      margin-bottom: 20px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      margin-right: 8px;
      margin-bottom: 8px;
      @include mq('phone-wide', max) {
        display: block;
        margin: 0 0 8px;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        padding: 6px 12px;
        background: #f5f7fa;

        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #1d2026;
        transition: all 0.3s;
        display: block;
        text-align: center;
        &:hover {
          color: #fff;
          background-color: #1d2026;
        }
      }
    }
  }
}

.custom-border {
  box-shadow: inset 0px 1px 0px #e9eaf0;
  height: 1px;
}
