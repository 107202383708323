//student amount sidebar

.course-gift-wrap {
  margin-right: 100px;
  @include mq('desktop', max) {
    margin-bottom: 30px;
    margin-right: 50px;
  }
  @include mq('tablet', max) {
    margin-right: 0;
  }
  .section-title {
    text-align: left;
    margin-bottom: 30px;
    h2{
      font-size: 32px;
    }
  }
  .contact-wrap {
    padding: 0;
    margin-bottom: 40px;
    .contact-title {
      margin-bottom: 24px;
      h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #1d2026;
        margin: 0;
      }
    }
    .icon {
      width: 31px;
    }
  }
  label.form-check-label {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4e5566 !important;
    margin: 0;
    margin-left: 10px;
  }
  .form-check-input {
    width: 22px;
    height: 22px;
  }
}

.amount-sidebar {
  @include mq('desktop', max) {
    margin-top: 30px;
  }
}
.amunt-detalis {
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #1d2026;
    margin-bottom: 15px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #6e7485;
      line-height: 22px;
      padding-bottom: 13px;
      &:last-child {
        border-bottom: 1px solid #e9eaf0;
      }
      span {
        float: right;
        color: #1d2026;
        font-weight: 500;
      }
    }
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.01em;
    color: #202029;
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq('phone-wide', max) {
      font-size: 20px;
    }
    span {
      color: #202029;
      font-weight: normal;
      font-size: 16px;
    }
  }
}

.amount-sidebar {
  border: 1px solid #e9eaf0;

  .course-details-wrap {
    padding: 24px;
    border-bottom: 1px solid #e9eaf0;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #1d2026;
      margin-bottom: 15px;
    }
    .course-details-item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      @include mq('phone-wide', max) {
        display: block;
        text-align: center;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .course-details-thumb {
        width: 100px;
        height: 75px;
        margin-right: 12px;
        flex: 0 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @include mq('phone-wide', max) {
          margin: 0 0 20px;
          width: 100%;
          height: 250px;
        }
      }
      .course-details-data {
        p {
          font-size: 12px;
          line-height: 16px;
          color: #a1a5b3;
          margin-bottom: 2px;
          span {
            color: #4e5566;
          }
        }
        .course-detalis-title {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #1d2026;
          margin-bottom: 10px;
        }
        h5 {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #ff6636;
          del {
            color: #a1a5b3;
          }
        }
      }
    }
  }
  .about-sidebar-wrap {
    padding: 24px;
  }
}

.amount-sidebar.public-shopping-cart {
  border: none;
  .about-sidebar-wrap {
    padding: 0;
  }
  .amaunt-confirm-button {
    .default-btn {
      @include mq('desktop-wide', max) {
        padding: 0 18px;
      }
    }
  }
}

.coupon-code {
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid #e9eaf0;
  h4 {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #1d2026;
    margin-bottom: 15px;
  }
  button#coupon-apply {
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: -0.004em;
    text-transform: capitalize;
    color: #fff;
    background: #1d2026;
    padding: 0 16px;
    position: absolute;
    top: 6px;
    right: 5px;
    transition: all 0.3s;
    &:hover {
      background-color: #ff6636;
    }
  }
}
