.modal {
  .btn-close {
    width: 18px;
    height: 18px;
    &:focus {
      box-shadow: none;
      border: none;
    }
  }

  &-content {
    border-radius: 0px;
  }

  &-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-transform: capitalize;
  }

  &-header {
    border: 0px;
    padding: 16px 20px;
    box-shadow: 0px -1px 0px 0px #e9eaf0 inset;
  }

  &-body {
    padding: 24px 20px;
    padding-bottom: 0px;

    &-rating {
      text-align: center;
      padding-bottom: 30px;
      p {
        font-size: 20px;
        color: #1d2026;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 16px;
        text-transform: capitalize;
        span {
          font-size: 18px;
          color: #8c94a3;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -1.5%;
        }
      }

      .jq-star {
        margin: 0px 6px;
      }
    }
  }

  &-footer {
    border: 0;
    display: flex;
    padding: 24px 20px;
    align-items: center;
    justify-content: space-between;
    .button {
      @include max(480px) {
        width: 100%;
      }
    }
  }

  &--newcard {
    .form {
      &-field,
      .form-field-social {
        margin-bottom: 18px;
      }

      &-group {
        .form-field {
          @include min(575px) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  &--compose {
    .form {
      &-selects {
        margin-bottom: 18px;
      }
    }
  }

  &--lecturevideo {
    .form-upload {
      margin-bottom: 16px;
    }
    p {
      @extend %text-one;
      color: #8c94a3;
      span {
        color: #1d2026;
        font-weight: 500;
      }
    }
  }

  &--cdescription {
    .form {
      &-textarea {
        margin-bottom: 24px;
      }
    }
  }
}
