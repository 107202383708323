* {
  margin: 0;
  padding: 0;
  outline: 0;
}

img {
  border: 0;
  outline: 0;
  max-width: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  font-weight: 400;
  font-family: $font-primary;
}

a {
  display: inline-block;
  // color: $lightBlack;
  text-decoration: none;

  &:hover {
    color: #fff;
    // background-color: #2E93fA;
  }
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  color: $black;
  line-height: 24px;
  margin: 0;
}

.space50 {
  padding: 50px;
}
.space100 {
  padding: 100px;
}
.space20 {
  padding: 20px;
}
.space10 {
  padding: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

button {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}

.bodybg{
  background-color: #f5f7fa;
}