.course-single-section {
  z-index: 0;
  position: relative;
  border-bottom: 1px solid #e9eaf0;

  &:before {
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 380px;
    z-index: -1;
    position: absolute;
    background-color: #f5f7fa;
    @include mq('tablet', max) {
      content: none;
    }
  }
}

.course-single-top {
  ul {
    margin: 0 0 12px;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      a {
        font-size: 14px;
        letter-spacing: -0.01em;
        color: #6e7485;
        svg {
          margin: 0 5px;
        }
      }
    }
  }
  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #1d2026;
    margin-bottom: 24px;
    @include mq('tablet', max) {
      font-size: 28px;
      line-height: 34px;
    }
    @include mq('phone-wide', max) {
      font-size: 22px;
      line-height: 30px;
    }
  }
  p {
    font-size: 20px;
    line-height: 32px;
    color: #4e5566;
    @include mq('phone-wide', max) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.course-singe-main {
  margin-top: 50px;
}

.course-right-sidebar {
  background: #ffffff;
  border: 1px solid #e9eaf0;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgb(0 0 0 / 6%);
  @include mq('desktop', max) {
    margin-top: 40px;
  }
}
.course-right-sidebar-row {
  border-bottom: 1px solid #e9eaf0;
  padding: 24px;
  &:last-child {
    border-bottom: 0;
  }
}

.course-right-sidebar-price {
  display: flex;
  align-items: start;
  justify-content: space-between;
  @include mq('phone-wide', max) {
    display: block;
  }
  .price-time {
    h4 {
      font-size: 24px;
      line-height: 32px;
      color: #1d2026;
      del {
        font-size: 16px;
        text-decoration-line: line-through;
        color: #8c94a3;
      }
    }
    h6 {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #e34444;
      svg {
        margin-right: 10px;
      }
    }
  }
}
.price-dicount {
  a {
    background: #ffeee8;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ff6636;
    padding: 8px 12px;
  }
}

.course-detalis {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #1d2026;
        display: block;
        svg {
          width: 25px;
          margin-right: 8px;
        }
        span {
          float: right;
          color: #6e7485;
        }
      }
    }
  }
}

.course-purchase-btn {
  .button {
    display: block;
    margin-bottom: 12px;
  }
  .add-to-wishlist-wrap {
    display: flex;
    justify-content: space-between;
    a.add-to-wishlist {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 40px;
      letter-spacing: -0.004em;
      text-transform: capitalize;
      color: #4e5566;
      border: 1px solid #e9eaf0;
      width: 100%;
      text-align: center;
      flex: 0 0 48.5%;
      margin-bottom: 12px;
    }
  }
  p {
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #6e7485;
    span {
      font-weight: 500;
      color: #6e7485;
    }
  }
}

.course-includes-wrap {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #4e5566;
        display: block;
        position: relative;
        padding-left: 35px;
        svg {
          width: 25px;
          position: absolute;
          left: 0;
          path {
            stroke: #ff6636;
          }
        }
      }
    }
  }
}
h4.course-sidebar-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1d2026;
  margin-bottom: 15px;
}

.course-share-wrap {
  .social-icon {
    ul {
      li {
        &:first-child a {
          width: 137px;
        }
        &:first-child a svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.course-video-wrap {
  height: 492px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  @include mq('tablet', max) {
    height: 390px;
  }
  @include mq('phone-wide', max) {
    height: 250px;
  }
  a.popup-youtube {
    width: 72px;
    height: 72px;
    background: #ffffff;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    line-height: 69px;
    transition: all 0.3s;
    @include mq('phone-wide', max) {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
    &:hover {
      background-color: #ff6636;
    }
    svg {
      position: relative;
      left: 2px;
      path {
        transition: all 0.3s;
      }
    }
    &:hover path {
      fill: #fff;
    }
  }
}

.student-profile-menu.single-course {
  border-bottom: 1px solid #e9eaf0;
  ul {
    @include mq('tablet', max) {
      display: flex;
    }
    @include mq('phone-wide', max) {
      display: block;
      text-align: center;
    }
  }
}

.overview-section {
  padding: 40px 0;
}
.overview-description {
  margin-bottom: 40px;
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4e5566;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

//course

.course-learn-section {
  padding: 40px;
  margin-bottom: 40px;
  background: rgba(225, 247, 227, 0.4);
  @include mq('phone-wide', max) {
    padding: 25px;
  }
  .checkbox-field {
    ul {
      li {
        margin-right: 0;
      }
    }
  }
}

.course-single-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq('tablet-small', max) {
    display: block;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
  }
}

.course-list-section {
  margin-bottom: 40px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      font-weight: normal;
      font-size: 14px;
      letter-spacing: -0.01em;
      color: #4e5566;
      margin-bottom: 14px;
      position: relative;
      padding-left: 32px;
      &:last-child {
        margin-bottom: 0;
      }
      svg {
        position: absolute;
        left: 0;
      }
    }
  }
  &.course-requirements {
    ul {
      li {
        padding-left: 15px;
        &::before {
          content: '';
          width: 5px;
          height: 5px;
          left: 0;
          top: 9px;
          border-radius: 50%;
          position: absolute;
          background-color: #4e5566;
        }
      }
    }
  }
}

.accordion-wrap-faq.lecture-wrap.course-watch .link {
  @include mq('desktop-wide', max) {
    display: block;
  }
  @include mq('desktop', max) {
    display: flex;
  }
  @include mq('tablet-small', max) {
    display: block;
  }
}
.course-curriculum-section {
  margin-bottom: 40px;
  .accordion-wrap-faq.lecture-wrap .link {
    padding: 18px 20px;
  }
  .accordion-wrap-faq.lecture-wrap .accordion__list2.open .link {
    background-color: transparent;
    border: 1px solid #e9eaf0;
    border-bottom: 1px solid #fff;
  }
  .lectue-body-item {
    padding: 7px 20px;
    &:hover {
      background-color: transparent;
    }
  }
  .curriculum-body-left {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4e5566;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  .curriculum-body-right {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #8c94a3;
  }
}

.course-instructor-section {
  margin-bottom: 40px;
}
.profile-wrap.course-instructor {
  border: 1px solid #e9eaf0;
  padding: 32px;
  margin-bottom: 20px;
  @include mq('phone-wide', max) {
    padding: 20px;
  }
  .profile-left {
    align-items: flex-start;
    .profile-thumb {
      width: 136px;
      height: 136px;
      @include mq('phone-wide', max) {
        height: 110px;
        width: 110px;
      }
    }
    .profile-data {
      p {
        font-size: 14px;
        line-height: 22px;
        a {
          color: #6e7485;
          font-weight: 500;
          text-transform: uppercase;
          transition: all 0.3s;
          &:hover {
            color: #1d2026;
          }
        }
      }
      h3 {
        font-size: 20px;
        margin-bottom: 6px;
      }
    }
    .profile-review {
      margin: 16px 0;
    }
  }
}

.rating-area {
  padding-bottom: 24px;
  display: flex;
  align-items: flex-start;
  @include mq('tablet', max) {
    display: block;
  }
  .rating-percentage {
    // max-width: 420px;
    .percentage-line {
      margin-bottom: 18px;
      .rating-area-percentage {
        margin: 0 12px;
      }
    }
  }
  &-percentage {
    position: relative;
    width: 350px;
    @include mq('desktop-wide', max) {
      width: 230px;
    }
    @include mq('tablet', max) {
      width: 59%;
    }
    @include mq('tablet-small', max) {
      width: 100%;
      margin: 0 !important;
    }
    .main-percentage {
      position: absolute;
      top: 0;
      left: 0;
    }
    .eighty-eight {
      width: 75%;
    }
    .three {
      width: 8%;
    }
    .four {
      width: 21%;
    }
    .two {
      width: 5%;
    }
    .one {
      width: 3%;
    }
  }
}

.rating-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 190px;
  border: 1px solid #e9eaf0;
  margin-right: 25px;
  @include mq('tablet', max) {
    margin-bottom: 30px;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1d2026;
    margin-bottom: 24px;
  }
  .total-star {
    margin-bottom: 14px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #1d2026;
  }
}
.total-star {
  padding: 0;
  margin: 0;
  li {
    margin-right: 0px !important;
    svg {
      // color: red;
      // fill: red;
      height: 20px;
      width: 20px;
    }
  }
  &-sm {
    line-height: 15px;
    li {
      margin-right: 2px !important;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
.main-percentage-bg {
  width: 100%;
  height: 8px;
  background-color: #fff2e6;
  &-null {
    background-color: #000;
  }
}
.main-percentage {
  height: 8px;
  width: 100%;
  background-color: #fd8e1f;
}
.percentage-text {
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.01em;
  }
}
.percentage-line {
  display: flex;
  align-items: center;
  @include mq('tablet-small', max) {
    display: block;
  }
}
.null-star {
  svg {
    color: #d2d1e0 !important;
    fill: transparent !important;
  }
}
.null-percentage {
  span {
    color: #b7b7cd;
  }
}

.rating {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.01em;
  color: #6e7485;
  margin-left: 6px;
  margin-right: 10px;
  @include mq('tablet-small', max) {
    text-align: left;
    margin: 10px 0;
  }
}

.select-box.ratting {
  label {
    float: none;
  }
  span.custom-select-trigger {
    margin: 0;
    border: 1px solid #e9eaf0;
    width: 200px;
    line-height: 48px;
    padding: 0 20px;
    font-size: 16px;
    color: #4e5566;
    &:after {
      border-bottom: 2px solid #1d2026;
      border-right: 2px solid #1d2026;
    }
  }
}

.student-feedback-section {
  .course-single-title {
    @include mq('tablet-small', max) {
      display: flex;
    }
    @include mq('phone-wide', max) {
      display: block;
    }
  }
}

//course watch

.course-watch-section {
  padding: 32px 22px 80px;
}
.course-content-area {
  margin-bottom: 24px;
  @include mq('desktop', max) {
    margin-top: 40px;
  }
  .course-content {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
    @include mq('phone-wide', max) {
      display: block;
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      letter-spacing: -0.01em;
      color: #1d2026;
      margin: 0;
      @include mq('phone-wide', max) {
        margin-bottom: 15px;
      }
    }
    h4 {
      font-weight: 600;
      font-size: 16px;
      color: #23bd33;
      margin: 0;
    }
  }
  .course-progress-gray {
    width: 100%;
    height: 4px;
    background: #e9eaf0;
    position: relative;
    .course-progress-green {
      height: 4px;
      background: #23bd33;
      width: 15%;
    }
  }
}
.course-youtube-video-wrap {
  iframe {
    width: 100%;
    height: 690px;
    @include mq('desktop-wide', max) {
      height: 500px;
    }

    @include mq('tablet-small', max) {
      height: 300px;
    }
  }
}

.course-single-top.course-watch {
  padding: 24px 0;
  border-bottom: 1px solid #e9eaf0;
  .withdrew-money-card-mid {
    margin: 0;
    @include mq('phone-wide', max) {
      display: block;
    }
  }
  .withdrew-money-card-mid-inner {
    align-items: center;
    @include mq('phone-wide', max) {
      margin-bottom: 15px;
    }
  }
  .withdrew-money-card-mid {
    img {
      width: 32px;
      height: 32px;
    }
    h5 {
      font-size: 12px;
      margin: 0;
      line-height: 1;
    }
    p {
      font-size: 14px !important;
      color: #1d2026 !important;
      span {
        color: #6e7485;
      }
    }
  }
  .withdrew-money-card-mid-inner-img-2,
  .withdrew-money-card-mid-inner-img-3,
  .withdrew-money-card-mid-inner-img-4,
  .withdrew-money-card-mid-inner-img-5 {
    margin-left: -24px;
  }
  a.withdrew-money-card-profile-name {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0 !important;
  }
}

.student-profile-menu.course-watch {
  border-bottom: 1px solid #e9eaf0;
  ul {
    @include mq('tablet-small', max) {
      display: block;
    }
    li {
      @include mq('tablet-small', max) {
        display: block;
      }
      a {
        padding: 20px 30px;
        @include mq('desktop-wide', max) {
          padding: 20px 0;
          font-size: 15px;
        }
        @include mq('desktop', max) {
          padding: 15px;
        }
      }
    }
  }
}

.course-watch-wrap {
  max-width: 916px;
  margin-top: 40px;
}

.lecture-notes {
  p {
    margin: 0;
  }
  ul {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    li {
      padding-left: 15px;
      position: relative;

      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #4e5566;
      &:before {
        content: '';
        width: 5px;
        height: 5px;
        left: 0;
        top: 9px;
        border-radius: 50%;
        position: absolute;
        background-color: #4e5566;
      }
    }
  }
}

.course-attach-file {
  margin-bottom: 40px;
}
