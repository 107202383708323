// texts sizes
%text-one {
  font-size: 14px;
  color: #6e7485;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
}

%text-two {
  font-size: 18px;
  color: #1d2026;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}

%text-three {
  font-size: 24px;
  color: #1d2026;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
}

%text-four {
  font-size: 40px;
  color: #1d2026;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
}

%label-text {
  font-size: 14px;
  color: #1d2026;
  line-height: 22px;
  margin-bottom: 6px;
  letter-spacing: -0.01em;
  text-transform: capitalize;
}

// Input field
%input {
  width: 100%;
  height: 48px;
  font-size: 16px;
  color: #4e4e4e;
  line-height: 24px;
  padding: 12px 18px;
  text-transform: capitalize;
  transition: all 0.3s linear;
  border: 1px solid #e9eaf0;
  &::placeholder {
    color: #8c94a3;
  }
  &:hover {
    border: 3px solid #ffddd1;
  }
  &:active,
  &:focus {
    border: 1px solid #ff6636;
  }
}
