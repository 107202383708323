.notification-sections {
  padding: 20px;
  background-color: #fff;

  .notification-item {
    display: flex;
    padding-bottom: 12px;
    background-color: #fff;

    &-left {
      &-icon {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        line-height: 29px;
        text-align: center;
        background-color: $primary-500;
        margin-right: 12px;
        cursor: pointer;
      }
    }
    &-right {
      &-content {
        a {
          margin: 0;

          font-size: 14px;
          line-height: 22px;
          letter-spacing: -0.01em;
          color: #4e5566;
        }

        span {
          font-size: 12px;
          line-height: 16px;
          color: #8c94a3;
          margin-top: 6px;
        }
      }
    }
  }
}
