// ==== Responsive   ====== \\

// 1
@mixin max($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin min($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin min-max($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

// Example

// .example {
//   @include max(575px) {

//   }

//   @include min(575px) {

//   }

//   @include min-max(320px, 575px) {

//   }

// }

// 2
$breakpoints: (
  'phone': 320px,
  'phone-wide': 480px,
  //here
  // "phablet":      560px,
  'tablet-small': 768px,
  'tablet': 992px,
  //here
  // "tablet-wide":  1024px,
  'desktop': 1200px,
  //here
  'desktop-wide': 1500px,
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

// Example
// .site-header {
//     padding: 2rem;
//     font-size: 1.8rem;
//     @include mq('tablet-wide') {
//         padding-top: 4rem;
//         font-size: 2.4rem;
//     }
// }
