.section-padding {
  padding: 80px 0;
  @include mq('tablet-small', max) {
    padding: 60px 0;
  }
}
.section-padding-bottom {
  padding-bottom: 100px;
  @include mq('tablet-small', max) {
    padding-bottom: 60px;
  }
}
.section-title {
  text-align: center;
  margin-bottom: 40px;
  h2 {
    font-weight: 600;
    font-size: 40px;
    letter-spacing: -0.01em;
    color: #1d2026;
    @include mq('tablet-small', max) {
      font-size: 32px;
    }
    @include mq('phone-wide', max) {
      font-size: 28px;
    }
  }
}

.section-title.section-title2 {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  margin-bottom: 30px;
  h2{
    margin-bottom: 15px;
  }
  @include mq('tablet', max) {
    display: block;
    text-align: center;
  }
  @include mq('phone-wide', max) {
    padding-top: 30px;
    padding: 0 20px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #4e5566;
  }
}
.section-title.course-title{
  margin-bottom: 55px;
}
.hero-section {
  min-height: 548px;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center right;
  background-image: none !important;
  overflow: hidden;
  position: relative;
  z-index: 0;
  background: #f0f2f5;
  &:before {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    @include mq('tablet', max) {
      content: '';
    }
  }
  @include mq('tablet', max) {
    background: none;
    background-image: url(./src/images/hero-thumb.jpg) !important;
  }
  @include mq('phone-wide', max) {
    min-height: 475px;
  }
}
.st_screenfix {
  margin-right: calc((-100vw + 100%) / 2);
  max-width: 1640px;
}
.hero-thumb {
  height: 548px;
  position: relative;
  left: 5px;
  -webkit-clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0 100%);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include mq('tablet', max) {
    display: none;
  }
}
.hero-content {
  @include max(991px) {
    text-align: center;
  }
  h1 {
    font-weight: 600;
    font-size: 72px;
    letter-spacing: -0.02em;
    color: #1d2026;
    margin-bottom: 40px;
    line-height: 74px;
    @include max(1200px) {
      font-size: 48px;
      line-height: 62px;
      margin-bottom: 20px;
    }
    @include max(575px) {
      font-size: 36px;
      line-height: 42px;
    }
  }
  p {
    font-size: 24px;
    line-height: 32px;
    color: #4e5566;
    margin-bottom: 35px;
    @include max(1200px) {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 20px;
    }
  }
}
