// Colors

$color: #00c99c;
$black: #333;
$white: white;
$bgColor: #f6f8fa;
$lightBlack: #777;
$orange: #fd6179;
$blue: #1f7ae2;
$purple: #4e5bde;
$primary-500: #ff6636;
$primary-100: #ffeee8;
$primary-600: #cc522b;
