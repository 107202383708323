.progress-content {
  padding: 24px;
  text-align: center;
  transition: 0.3s;
  background-color: #fff;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0px 12px 32px rgba(29, 32, 38, 0.1);
  }

  &:hover .category-content-left {
    background-color: $primary-500;

    svg {
      path {
        stroke: #fff;
      }
    }
  }

  &-left {
    height: 80px;
    width: 80px;
    line-height: 80px;
    text-align: center;
    margin-bottom: 24px;
    display: inline-block;
    background: rgba(86, 79, 253, 0.1);

    @include max(767px) {
      margin-bottom: 12px;
    }

    svg {
      transition: 0.3s;

      path {
        transition: 0.3s;
      }
    }
  }

  &-right {
    align-self: center;

    a {
      @extend %text-two;
      margin-bottom: 6px;
      white-space: nowrap;
      color: #1d2026;
    }

    p {
      @extend %text-one;
    }
  }
}

.progress-content-color-1 {
  .progress-content-left {
    background-color: #e1f7e3;
  }
}

.progress-content-color-2 {
  .progress-content-left {
    background-color: #fff2e5;
  }
}

.progress-content-color-3 {
  .progress-content-left {
    background-color: #ffeee8;
  }
}

.progress-content-color-4 {
  .progress-content-left {
    background-color: #fff0f0;
  }
}
