.payment-card-visa {
  background-image: radial-gradient(#453fca, #342f98);
  padding: 24px;
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;

  svg {
    z-index: 1;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 28px;

    svg {
      z-index: 1;
    }

    button {
      background-color: transparent;
      border: 0;
    }
  }

  &-mid {
    display: flex;
    padding-bottom: 24px;

    input {
      background: transparent;
      border: 0;

      font-size: 24px;
      line-height: 32px;
      color: #fff;
      padding-right: 27px;
      z-index: 1;
      width: 252px;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      color: #fff;
      padding-right: 27px;
      z-index: 1;
    }

    svg {
      z-index: 1;
    }

    button {
      background-color: transparent;
      border: 0;
    }
  }

  svg {
    z-index: 9999;
  }

  &-footer {
    display: flex;

    &-left {
      padding-right: 144px;
    }

    &-info {
      span {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #ffffffad;
        z-index: 1;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #fff;
        z-index: 1;
      }
    }
  }
}

.payment-card-visa::after {
  position: absolute;
  content: ' ';
  bottom: -96px;
  right: -60px;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  background-color: #ffffff0d;
  z-index: -1;
}

.payment-card-visa:before {
  position: absolute;
  content: ' ';
  top: -43px;
  left: 18px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #ffffff0d;
}

.payment-card-visa-2 {
  background-image: radial-gradient(#b63636, #882929);
  padding: 24px;
  position: relative;
  overflow: hidden;
  width: 100%;
  z-index: 1;

  svg {
    z-index: 1;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    svg {
      z-index: 1;
    }

    button {
      background-color: transparent;
      border: 0;
    }
  }

  &-mid {
    display: flex;
    padding-bottom: 20px;

    input {
      background: transparent;
      border: 0;

      font-size: 24px;
      line-height: 32px;
      color: #fff;
      padding-right: 27px;
      z-index: 1;
      width: 252px;
    }

    p {
      font-size: 24px;
      line-height: 32px;
      color: #fff;
      padding-right: 27px;
      z-index: 1;
    }

    svg {
      z-index: 1;
    }

    button {
      background-color: transparent;
      border: 0;
    }
  }

  svg {
    z-index: 9999;
  }

  &-footer {
    display: flex;

    &-left {
      padding-right: 144px;
    }

    &-info {
      span {
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #ffffffad;
        z-index: 1;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #fff;
        z-index: 1;
      }
    }
  }
}

.payment-card-visa-2::after {
  position: absolute;
  content: ' ';
  top: -96px;
  right: -5px;
  height: 236px;
  width: 236px;
  border-radius: 50%;
  background-color: #ffffff0d;
  z-index: -1;
}

.payment-card-visa-2:before {
  position: absolute;
  content: ' ';
  bottom: -7px;
  left: -32px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #ffffff0d;
}
.card-body-wrap {
  padding: 20px;
}

.payment-card-slider{
  .owl-dots{
    bottom: -58px;
    position: absolute;
    .owl-dot{
      span{
        width: 10px;
        height: 10px;
        margin: 0 3px;
        background: #FF6636;
        display: block;
        transition: all 0.4s;
        border-radius: 30px;
      }
    }
    .owl-dot.active span{
      background: #869791;
    }
  }
}

.card-owl-nav {
  text-align: right;
  margin: 30px 0;
}

.card-next-button,
.card-prev-button{
  cursor: pointer;
  path{
    stroke:#CED1D9;
    transition: all 0.4s;
  }
  &:hover path{
    stroke: #4E5566;
  }
}
