.category-section .category-content {
  margin-bottom: 24px;
}
.category-content {
  display: flex;
  padding: 20px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0px 12px 32px rgba(29, 32, 38, 0.1);
    background-color: #fff;
  }

  &:hover .category-content-left {
    svg {
      path {
        stroke: #fff;
      }
    }
  }

  &-left {
    height: 64px;
    width: 64px;
    line-height: 64px;
    text-align: center;
    margin-right: 20px;
    background-color: #fff;
    transition: 0.3s;

    svg {
      transition: 0.3s;

      path {
        transition: 0.3s;
      }
    }
  }

  &-right {
    align-self: center;

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1d2026;
      margin: 0;
      display: block;
      margin-bottom: 5px;
    }

    span {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #6e7485;
      margin-top: 8px;
    }
  }
}

.icon-bottom-btn {
  text-align: center;
  p {
    font-size: 14px;
    letter-spacing: -0.01em;
    color: #4e5566;
    a {
      color: #ff6636;
      font-weight: 500;
      margin-left: 10px;
      svg {
        margin-left: 5px;
      }
    }
  }
}
